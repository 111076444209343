import { AuthState } from "@/stores/auth/types";
import { defineStore } from "pinia";
import { changePassword, login, recoverPassword, newPassword } from "@/api/auth/auth-api";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { ChangePasswordBody, LoginBody, RecoverPasswordEmailBody, RecoverPasswordBody } from "@/api/auth/types";
import { showSuccess } from "@/common/utils/notification-utils";
import router from "@/router";
import { LocationQueryValue } from "vue-router";

const { startLoading } = createActionHelpers();

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    access_token: localStorage.getItem("accessToken") || "",
    login_first_time: localStorage.getItem("loginFirstTime") === "true",
    token_type: localStorage.getItem("tokenType") || "",
  }),
  actions: {
    async changePassword(changePasswordBody: ChangePasswordBody, fromModal: boolean) {
      const response = await startLoading("auth", changePassword(changePasswordBody));

      showSuccess(response.data.message);

      if (!fromModal) {
        localStorage.setItem("loginFirstTime", String(false));
        this.login_first_time = false;
        await router.push({ name: "search" });
      }

      return true;
    },
    async recoverPassword(recoverPasswordEmailBody: RecoverPasswordEmailBody) {
      await startLoading("auth", recoverPassword(recoverPasswordEmailBody));
      await router.push({ name: "check-email" });
    },
    async newPassword(recoverPasswordBody: RecoverPasswordBody, token: string | undefined | LocationQueryValue | LocationQueryValue[]) {
      if (typeof token === "string") {
        this.access_token = token;
        this.token_type = "Bearer";
      }

      await startLoading("auth", newPassword(recoverPasswordBody));
      this.access_token = "";
      this.token_type = "";
      await router.push({ name: "login" });
    },
    async login(loginBody: LoginBody) {
      const response = await startLoading("auth", login(loginBody));

      this.access_token = response.data.access_token;
      this.login_first_time = response.data.login_first_time;
      this.token_type = response.data.token_type;

      localStorage.setItem("accessToken", response.data.access_token);
      localStorage.setItem("loginFirstTime", response.data.login_first_time);
      localStorage.setItem("tokenType", response.data.token_type);

      if (this.login_first_time) {
        await router.push({ name: "change-password" });
      } else {
        await router.push({ name: "search" });
      }
    },
    async logout() {
      this.access_token = "";
      this.login_first_time = false;
      this.token_type = "";

      localStorage.removeItem("accessToken");
      localStorage.removeItem("loginFirstTime");
      localStorage.removeItem("tokenType");
    },
  },
});
