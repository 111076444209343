<template>
  <!-- Layout component -->
  <component :is="currentLayout" v-if="isRouterLoaded">
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <div class="h-full">
          <component :is="Component" />
        </div>
      </transition>
    </router-view>
  </component>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import authLayout from "@/layouts/AuthLayout.vue";
import defaultLayout from "@/layouts/DefaultLayout.vue";

export default defineComponent({
  components: { authLayout, defaultLayout },
  setup() {
    const route = useRoute();

    const currentLayout = computed(() => {
      return `${route.meta?.layout || "auth"}Layout`;
    });

    const isRouterLoaded = computed(() => {
      if (route.name !== null) return true;

      return false;
    });

    return { currentLayout, isRouterLoaded };
  },
});
</script>

<style lang="scss"></style>
