import { ProfileState } from "./types";
import { defineStore } from "pinia";
import { getUser, getUserSearchHistory, updateUser, updateUserCurrency, updateUserLanguage } from "@/api/profile/profile-api";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { showSuccess } from "@/common/utils/notification-utils";
import { CurrencyBody, LanguageBody, UserBody } from "@/api/profile/types";
import i18n from "@/plugins/vue-i18n";

const { startLoading } = createActionHelpers();

export const useProfileStore = defineStore("profile", {
  state: (): ProfileState => ({
    currencyList: [],
    languageList: [],
    searchHistory: [],
    selectedCurrency: null,
    selectedLanguage: null,
    user: null,
  }),
  actions: {
    async getUser() {
      const response = await startLoading("user", getUser());
      this.user = response.data;

      if (this.user) {
        this.user = {
          ...this.user,
          address: this.user["address"] === null ? "" : this.user["address"],
          first_name: this.user["first_name"] === null ? "" : this.user["first_name"],
          last_name: this.user["last_name"] === null ? "" : this.user["last_name"],
        };
      }

      this.selectedCurrency = this.currencyList.find((option) => option.value === this.user?.currency) || null;
      this.selectedLanguage = this.languageList.find((option) => option.value === this.user?.language) || null;
    },
    getCurrencyList() {
      this.currencyList = [
        { name: "EUR", value: "euro" },
        { name: "USD", value: "usd" },
      ];
    },
    getLanguageList() {
      this.languageList = [
        { name: "ENG", value: "en" },
        { name: "GER", value: "de" },
      ];
    },
    async getUserSearchHistory() {
      const response = await startLoading("userSearchHistory", getUserSearchHistory());
      this.searchHistory = response.data;
    },
    async setUserCurrency(currencyBody: CurrencyBody) {
      const response = await startLoading("user", updateUserCurrency(currencyBody));
      showSuccess(response.data.message);

      if (this.user !== null) {
        this.user.currency = currencyBody.currency;
      }
    },
    async setUserLanguage(languageBody: LanguageBody) {
      const response = await startLoading("user", updateUserLanguage(languageBody));
      showSuccess(response.data.message);

      if (this.user !== null) {
        this.user.language = languageBody.language;
      }
    },
    async updateUser(userBody: UserBody) {
      await startLoading("user", updateUser(userBody));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      showSuccess(i18n.global.t("profile.profileUpdated"));
    },
  },
});
