import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { ClubState, MarketValue } from "@/stores/club/types";
import { getClubs } from "@/api/club/club-api";

const { startLoading } = createActionHelpers();

export const useClubStore = defineStore("club", {
  state: (): ClubState => ({
    clubs: [],
    selectedClubMarketValue: null,
  }),
  actions: {
    async getClubs() {
      const response = await startLoading("club", getClubs());
      this.clubs = response.data;
    },
    async setSelectedClubMarketValue(selectedOption: MarketValue) {
      this.selectedClubMarketValue = selectedOption;
    },
  },
});
