import { createAxiosRequest } from "@/api/api-base";
import { BenchmarkMarketValueBody, RiskBenchmarkBody, ImpactAdvancedBody } from "@/api/player/types";

const resource = "player";

export async function getEstimatedMarketValueRange() {
  return await createAxiosRequest(resource, 1, true).get("estimated_market_value_range");
}

export async function getPlayerAgents() {
  return await createAxiosRequest(resource, 1, true).get("/agent");
}

export async function getPlayerAgeValueRange() {
  return await createAxiosRequest(resource, 1, true).get("/age_range");
}

export async function getPlayerRisk(id: number) {
  return await createAxiosRequest(resource, 1, true).get(`${id}/risk`);
}

export async function getPlayerRiskBenchmark(riskBenchmarkBody: RiskBenchmarkBody) {
  return await createAxiosRequest(resource, 1, true).get("/risk_benchmark", {
    params: { ...riskBenchmarkBody },
  });
}

export async function getPlayerBenchmarkMarketValue(playerId: number, benchmarkMarketValueBody: BenchmarkMarketValueBody) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/market_value/market_value_benchmark`, {
    params: { ...benchmarkMarketValueBody },
  });
}

export async function getPlayerBreakdown(playerId: number) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/impact_cluster`);
}

export async function getPlayerContract() {
  return await createAxiosRequest(resource, 1, true).get("/player_contract");
}

export async function getPlayerFoot() {
  return await createAxiosRequest(resource, 1, true).get("/player_foot");
}

export async function getPlayerGeneral(playerId: number) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/general`);
}

export async function getPlayerHeightValueRange() {
  return await createAxiosRequest(resource, 1, true).get("/height_range");
}
export async function getPlayerImpactAdvance(playerId: number, impactAdvanceBody: ImpactAdvancedBody) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/impact_advance`, {
    params: { ...impactAdvanceBody },
  });
}

export async function getPlayerMarketValue(playerId: number, simulatedClubId: number) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/market_value`, { params: { simulated_club_id: simulatedClubId } });
}

export async function getPlayerNationalities() {
  return await createAxiosRequest(resource, 1, true).get("/nationality");
}

export async function getPlayerPositions() {
  return await createAxiosRequest(resource, 1, true).get("/position");
}

export async function getPlayerWeightValueRange() {
  return await createAxiosRequest(resource, 1, true).get("/weight_range");
}

export async function getPlayerLastUpdateDate(playerId: number) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/last_update_date`);
}

export async function getPlayerCompetitions() {
  return await createAxiosRequest(resource, 1, true).get("/competition_names");
}

export async function getPlayerForecastRange() {
  return await createAxiosRequest(resource, 1, true).get("/forecast_range_1y");
}

export async function getPlayerClusterMetadata(is_goalkeeper: boolean) {
  return await createAxiosRequest(resource, 1, true).get("/impact_cluster_metadata", {
    params: { is_goalkeeper },
  });
}

export async function getPlayerBenchmarkDistribution(playerId: number, benchmarkMarketValueBody: BenchmarkMarketValueBody) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/market_value/market_value_benchmark_distribution`, {
    params: { ...benchmarkMarketValueBody },
  });
}

export async function getPlayerMatchDays(playerId: number) {
  return await createAxiosRequest(resource, 1, true).get(`/${playerId}/market_value_weekly`);
}
