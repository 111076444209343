import { defineStore } from "pinia";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { CheckboxObject, Competition, PlayerCompareItem, PlayerImpactAdvance, PlayerImpactAdvanceItem, PlayerState } from "@/stores/player/types";
import {
  getEstimatedMarketValueRange,
  getPlayerAgents,
  getPlayerAgeValueRange,
  getPlayerBenchmarkDistribution,
  getPlayerBenchmarkMarketValue,
  getPlayerBreakdown,
  getPlayerClusterMetadata,
  getPlayerCompetitions,
  getPlayerContract,
  getPlayerFoot,
  getPlayerForecastRange,
  getPlayerGeneral,
  getPlayerHeightValueRange,
  getPlayerImpactAdvance,
  getPlayerLastUpdateDate,
  getPlayerMarketValue,
  getPlayerMatchDays,
  getPlayerNationalities,
  getPlayerPositions,
  getPlayerRisk,
  getPlayerRiskBenchmark,
  getPlayerWeightValueRange,
} from "@/api/player/player-api";
import ArrayUtils from "@/common/utils/array-utils";
import { BenchmarkMarketValueBody, RiskBenchmarkBody, ImpactAdvancedBody } from "@/api/player/types";
import CompareSortType from "@/common/constants/compare";
import { mapPlayerCompare, sortBySelectedAttributes, sortValuesAlphabetically, sortValuesHighToLow } from "@/stores/player/utils";

const { startLoading } = createActionHelpers();

export const usePlayerStore = defineStore("player", {
  state: (): PlayerState => ({
    estimatedMarketValueRange: null,
    playerAgents: [],
    playerAgeValueRange: null,
    playerBenchmarkDistribution: null,
    playerBenchMarkMarketValue: null,
    playerBreakdown: null,
    playerCompare: [],
    playerCompareCopy: [],
    playerCompetitions: [],
    playerContracts: [],
    playerFeet: [],
    playerForecastRange: null,
    playerGeneral: null,
    playerImpactAdvance: null,
    playerImpactAdvanceCopy: null,
    playerImpactAdvanceKeys: [],
    playerImpactAdvanceKeysCopy: [],
    playerImpactClusterMetadata: [],
    playerHeightValueRange: null,
    playerLastUpdated: "",
    playerMarketValue: null,
    playerMatchDays: [],
    playerNationalities: [],
    playerPositions: [],
    playerRisk: null,
    playerRiskBenchmark: null,
    playerSelectedClub: null,
    playerSortOptions: {
      sortByColumn: 0,
      sortByKey: true,
      sortKeyBy: CompareSortType.ATOZ,
      sortValueBy: CompareSortType.HIGHTOLOW,
    },
    playerWeightValueRange: null,
  }),
  actions: {
    async getEstimatedMarketValueRange() {
      const response = await startLoading("player", getEstimatedMarketValueRange());
      this.estimatedMarketValueRange = response.data.estimated_market_value_range;
    },
    async getPlayerAgents() {
      const response = await startLoading("player", getPlayerAgents());
      this.playerAgents = ArrayUtils.setStringArrayToObjectArray(response.data);
    },
    async getPlayerAgeValueRange() {
      const response = await startLoading("player", getPlayerAgeValueRange());
      this.playerAgeValueRange = response.data.age_range;
    },
    async getPlayerRisk(id: number, loadingPage = true) {
      const response = await startLoading(loadingPage ? "player" : "playerRisk", getPlayerRisk(id));
      this.playerRisk = response.data?.risk;
    },
    async getPlayerRiskBenchmark(riskBenchmarkBody: RiskBenchmarkBody, loadingPage = true) {
      const response = await startLoading(loadingPage ? "player" : "playerRiskBenchmark", getPlayerRiskBenchmark(riskBenchmarkBody));
      this.playerRiskBenchmark = response.data;
    },
    async getPlayerBenchmarkDistribution(playerId: number, benchmarkMarketValueBody: BenchmarkMarketValueBody, loadingPage = true) {
      const response = await startLoading(
        loadingPage ? "player" : "playerBenchmarkDistribution",
        getPlayerBenchmarkDistribution(playerId, benchmarkMarketValueBody)
      );

      this.playerBenchmarkDistribution = response.data;
    },
    async getPlayerBenchmarkMarketValue(playerId: number, benchmarkMarketValueBody: BenchmarkMarketValueBody, loadingPage = true) {
      const response = await startLoading(
        loadingPage ? "player" : "playerBenchmarkMarketValue",
        getPlayerBenchmarkMarketValue(playerId, benchmarkMarketValueBody)
      );
      this.playerBenchMarkMarketValue = response.data;
    },
    async getPlayerBreakdown(playerId: number) {
      const response = await startLoading("player", getPlayerBreakdown(playerId));
      this.playerBreakdown = response.data;
    },
    async getPlayerContract() {
      const response = await startLoading("player", getPlayerContract());
      this.playerContracts = ArrayUtils.setStringArrayToObjectArray(response.data);
    },
    async getPlayerCompetition() {
      const response = await startLoading("player", getPlayerCompetitions());

      this.playerCompetitions = response.data.map((competition: Competition) => {
        return { name: competition.name, value: competition.competitionId };
      });
    },
    async getPlayerFoot() {
      const response = await startLoading("player", getPlayerFoot());
      this.playerFeet = ArrayUtils.setStringArrayToObjectArray(response.data);
    },
    async getPlayerForecastRange() {
      const response = await startLoading("player", getPlayerForecastRange());
      this.playerForecastRange = response.data.forecast_range;
    },
    async getPlayerGeneral(playerId: number, isPlayerToCompare = false, compareNumber = 0) {
      const response = await startLoading("player", getPlayerGeneral(playerId));

      if (isPlayerToCompare) {
        this.playerCompare = mapPlayerCompare(this.playerCompare, response.data, "general", compareNumber);
        this.playerCompareCopy = mapPlayerCompare(this.playerCompareCopy, response.data, "general", compareNumber);
      } else {
        this.playerGeneral = response.data;
      }
    },
    async getPlayerHeightValueRange() {
      const response = await startLoading("player", getPlayerHeightValueRange());
      this.playerHeightValueRange = response.data.height_range;
    },
    async getPlayerImpactAdvance(
      playerId: number,
      impactAdvanceBody: ImpactAdvancedBody,
      isPlayerToCompare = false,
      compareNumber = 1,
      loadPage = true
    ) {
      const response = await startLoading(loadPage ? "player" : "playerAdvancedBreakdown", getPlayerImpactAdvance(playerId, impactAdvanceBody));

      this.playerImpactAdvance = null;
      this.playerImpactAdvanceCopy = null;
      this.playerImpactAdvanceKeys = [];
      this.playerImpactAdvanceKeysCopy = [];

      if (this.playerImpactAdvanceKeys.length === 0) {
        Object.entries(response.data).forEach(([key, value]) => {
          this.playerImpactAdvanceKeys.push({
            name: (value as PlayerImpactAdvanceItem)?.surface_name,
            tooltip: (value as PlayerImpactAdvanceItem)?.tooltip,
            value: false,
          });
          this.playerImpactAdvanceKeysCopy.push({
            name: (value as PlayerImpactAdvanceItem)?.surface_name,
            tooltip: (value as PlayerImpactAdvanceItem)?.tooltip,
            value: false,
          });
        });
      }

      if (isPlayerToCompare) {
        this.playerCompare = mapPlayerCompare(this.playerCompare, response.data, "impactAdvanced", compareNumber);
        this.playerCompareCopy = mapPlayerCompare(this.playerCompareCopy, response.data, "impactAdvanced", compareNumber);

        await this.setAdvancedImpactSort(
          this.playerSortOptions.sortKeyBy === CompareSortType.ATOZ,
          this.playerSortOptions.sortValueBy === CompareSortType.HIGHTOLOW,
          false,
          true
        );
      } else {
        this.playerImpactAdvance = response.data;
        this.playerImpactAdvanceCopy = response.data;

        await this.setAdvancedImpactSort(
          this.playerSortOptions.sortKeyBy === CompareSortType.ATOZ,
          this.playerSortOptions.sortValueBy === CompareSortType.HIGHTOLOW,
          true,
          true
        );
      }
    },
    async getPlayerClusterMetadata(is_goalkeeper: boolean) {
      const response = await startLoading("player", getPlayerClusterMetadata(is_goalkeeper));
      this.playerImpactClusterMetadata = response.data;
    },
    async getPlayerLastUpdated(playerId: number) {
      const response = await startLoading("player", getPlayerLastUpdateDate(playerId));
      this.playerLastUpdated = response.data.update_date;
    },
    async getPlayerMarketValue(playerId: number, simulatedClubId: number, isPlayerToCompare = false, compareNumber = 1) {
      const response = await startLoading("player", getPlayerMarketValue(playerId, simulatedClubId));

      if (isPlayerToCompare) {
        this.playerCompare = mapPlayerCompare(this.playerCompare, response.data, "marketValue", compareNumber);
        this.playerCompareCopy = mapPlayerCompare(this.playerCompareCopy, response.data, "marketValue", compareNumber);
      } else {
        this.playerMarketValue = response.data;
      }
    },
    async getPlayerMatchDays(playerId: number) {
      const response = await startLoading("player", getPlayerMatchDays(playerId));
      this.playerMatchDays = response.data;
    },
    async getPlayerNationalities() {
      const response = await startLoading("player", getPlayerNationalities());
      this.playerNationalities = ArrayUtils.setStringArrayToObjectArray(response.data);
    },
    async getPlayerPositions() {
      const response = await startLoading("player", getPlayerPositions());
      this.playerPositions = ArrayUtils.setStringArrayToObjectArray(response.data);
    },
    async getPlayerWeightValueRange() {
      const response = await startLoading("player", getPlayerWeightValueRange());
      this.playerWeightValueRange = response.data.weight_range;
    },
    async setAdvancedImpactSort(isAscending: boolean, isHigh: boolean, isPlayerPage: boolean, isContributionOnMarketValue: boolean) {
      await this.setAdvancedImpactSelectedAttributes(isPlayerPage);

      if (this.playerSortOptions.sortByKey) {
        await this.setAdvancedImpactSortKeysAlphabetical(isAscending);
        await this.setAdvancedImpactSortValuesAlphabetical(isAscending, isPlayerPage);
      } else {
        await this.setAdvancedImpactSortHighLow(isHigh, isPlayerPage, isContributionOnMarketValue);
      }
    },
    async setAdvancedImpactSelectedAttributes(isPlayerPage: boolean) {
      const selectedAttributes = this.playerImpactAdvanceKeys.filter((item: CheckboxObject) => item.value);
      this.playerImpactAdvanceKeys = selectedAttributes.length > 0 ? selectedAttributes : this.playerImpactAdvanceKeys;

      if (selectedAttributes.length > 0) {
        if (isPlayerPage) {
          this.playerImpactAdvance = Object.fromEntries(
            sortBySelectedAttributes(selectedAttributes, this.playerImpactAdvance as PlayerImpactAdvance)
          );
        } else {
          this.playerCompare.forEach((playerCompareItem: PlayerCompareItem, index: number) => {
            const playerCompareObject = this.playerCompare[index];

            if (playerCompareObject.impactAdvanced !== null) {
              playerCompareItem.impactAdvanced = Object.fromEntries(
                sortBySelectedAttributes(selectedAttributes, playerCompareObject?.impactAdvanced)
              );
            }
          });
        }
      }
    },
    async setAdvancedImpactSortKeysAlphabetical(isAscending: boolean) {
      const sorted = this.playerImpactAdvanceKeys?.sort((a, b) => a.name.localeCompare(b.name));
      this.playerImpactAdvanceKeys = isAscending ? sorted : sorted?.reverse();
    },
    async setAdvancedImpactSortValuesAlphabetical(isAscending: boolean, isPlayerPage: boolean) {
      if (isPlayerPage) {
        this.playerImpactAdvance = sortValuesAlphabetically(this.playerImpactAdvance as PlayerImpactAdvance, isAscending);
      } else {
        this.playerCompare.forEach((item: PlayerCompareItem, index: number) => {
          const objectsToSort = this.playerCompare[index];

          if (objectsToSort.impactAdvanced !== null) {
            item.impactAdvanced = sortValuesAlphabetically(objectsToSort?.impactAdvanced, isAscending);
          }
        });
      }
    },
    async setAdvancedImpactSortHighLow(isHigh: boolean, isPlayerPage: boolean, isContributionOnMarketValue: boolean) {
      const selectedColumnData = this.playerCompare[this.playerSortOptions.sortByColumn];

      if (isPlayerPage) {
        this.playerImpactAdvance = sortValuesHighToLow(this.playerImpactAdvance as PlayerImpactAdvance, isHigh, isContributionOnMarketValue);
      } else {
        if (selectedColumnData.impactAdvanced !== null) {
          selectedColumnData.impactAdvanced = sortValuesHighToLow(selectedColumnData?.impactAdvanced, isHigh, isContributionOnMarketValue);

          this.playerCompare[this.playerSortOptions.sortByColumn].impactAdvanced = selectedColumnData.impactAdvanced;

          this.playerCompare.forEach((item: PlayerCompareItem, index: number) => {
            const objectsToSort = this.playerCompare[index];

            if (index !== this.playerSortOptions.sortByColumn && objectsToSort.impactAdvanced !== null) {
              item.impactAdvanced = Object.fromEntries(
                Object.entries(objectsToSort.impactAdvanced).sort((a, b) => {
                  const aKey = a[0];
                  const bKey = b[0];
                  return (
                    Object.entries(selectedColumnData.impactAdvanced!)
                      .map((el) => el[0])
                      .indexOf(aKey) -
                    Object.entries(selectedColumnData.impactAdvanced!)
                      .map((el) => el[0])
                      .indexOf(bKey)
                  );
                })
              );
            }
          });
        }

        this.playerImpactAdvanceKeys = this.playerImpactAdvanceKeys.sort((a, b) => {
          const aKey = a.name;
          const bKey = b.name;
          return (
            Object.entries(selectedColumnData.impactAdvanced!)
              .map((el) => el[1].surface_name)
              .indexOf(aKey) -
            Object.entries(selectedColumnData.impactAdvanced!)
              .map((el) => el[1].surface_name)
              .indexOf(bKey)
          );
        });
      }
    },
    async setClearSortOptions() {
      this.playerSortOptions = {
        sortByColumn: 0,
        sortByKey: true,
        sortKeyBy: CompareSortType.ATOZ,
        sortValueBy: CompareSortType.HIGHTOLOW,
      };
    },
    async setClearSortValues() {
      this.playerCompare = this.playerCompareCopy.map((obj) => {
        return { ...obj };
      });
      this.playerImpactAdvance = this.playerImpactAdvanceCopy;
      this.playerImpactAdvanceKeys = this.playerImpactAdvanceKeysCopy;

      await this.setClearSortOptions();
    },
    async setPlayerCompareInitialValues() {
      const emptyPlayerCompareItem: PlayerCompareItem = { general: null, impactAdvanced: null, marketValue: null };

      this.playerCompare[0] = emptyPlayerCompareItem;
      this.playerCompare[1] = emptyPlayerCompareItem;
      this.playerCompare[2] = emptyPlayerCompareItem;

      this.playerCompareCopy[0] = emptyPlayerCompareItem;
      this.playerCompareCopy[1] = emptyPlayerCompareItem;
      this.playerCompareCopy[2] = emptyPlayerCompareItem;

      this.playerImpactAdvanceKeys = [];
      this.playerImpactAdvanceKeysCopy = [];
    },
    async setPlayerInitialValues() {
      this.playerGeneral = null;
      this.playerBenchMarkMarketValue = null;
      this.playerBreakdown = null;
      this.playerImpactAdvance = null;
      this.playerSelectedClub = null;
      this.playerMarketValue = null;
    },
  },
});
