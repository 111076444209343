/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { useLoaderStore } from "../loader";

export const createActionHelpers = (): any => {
  return {
    // start and stop helpers for async processes
    async startLoading(loaderName: string, callback: Promise<AxiosResponse<unknown>>) {
      const loaderStore = useLoaderStore();
      loaderStore.load(loaderName);

      try {
        const response = await callback;
        loaderStore.end(loaderName);
        return response;
      } catch (error: any) {
        loaderStore.end(loaderName);
        throw error.response;
      }
    },
    endLoading(loaderName: string) {
      const loaderStore = useLoaderStore();
      loaderStore.end(loaderName);
    },
  };
};
