// values must match "error" keys in en.ts for translations
enum ErrorType {
  NOT_FOUND = "notFound",
  UNAUTHORIZED = "unauthorized",
  FORBIDDEN = "forbidden",
  SERVER_ERROR = "serverError",
  SERVICE_UNAVAILABLE = "serviceUnavailable",
  TOKEN_INVALID = "invalidToken",
  UNKNOWN_ERROR = "unknownError",
}

export default ErrorType;
