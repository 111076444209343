import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/LoginView.vue"),
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/auth/ChangePassword.vue"),
    meta: {
      layout: "auth",
      requiresAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
  },
  {
    path: "/check-email",
    name: "check-email",
    component: () => import("@/views/auth/CheckEmail.vue"),
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "auth",
      requiresAuth: false,
    },
  },
] as RouteRecordRaw[];
