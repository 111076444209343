<template>
  <div :class="[isComparePlayersView ? '' : 'h-screen', 'flex flex-col']">
    <navigation></navigation>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Navigation from "@/components/AppNavigation.vue";
import router from "@/router";

const isComparePlayersView = computed(() => router.currentRoute.value.name === "compare-players")
</script>
