import { LoaderState } from './types';
import ArrayUtils from '@/common/utils/array-utils';
import { defineStore } from 'pinia'

export const useLoaderStore = defineStore('loader', {
  state: (): LoaderState => ({
    activeLoaders: []
  }),
  getters: {
    getLoaderByName: (state) => { 
      return (loaderName: string): boolean => !!state.activeLoaders.find((loader: string) => loader === loaderName)
    },
  },
  actions: {
    load(loaderName: string) {
      this.activeLoaders.push(loaderName);
      this.activeLoaders = ArrayUtils.Uniq(this.activeLoaders);
    },
    end(loaderName: string) {
      this.activeLoaders = ArrayUtils.Uniq(this.activeLoaders).filter((p) => p !== loaderName);
    }
  }
});
