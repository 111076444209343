import { createAxiosRequest } from "@/api/api-base";
import { AxiosResponse } from "axios";
import { ClubForPlayersBody } from "@/api/club/types";

const resource = "club";

export async function getClubs() {
  return await createAxiosRequest(resource, 1, true).get("/");
}

export async function getPlayersInClub(clubForPlayersBody: ClubForPlayersBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).get(`/${clubForPlayersBody.club_id}/players`);
}
