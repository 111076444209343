import api from "@/configs/api";
import axios, { AxiosInstance } from "axios";
import { onRejected, responseInterceptor } from "./interceptors";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

export function createAxiosRequest(resource: string, version = 1, secured = true): AxiosInstance {
  const authStore = useAuthStore();
  const { access_token, token_type } = storeToRefs(authStore);

  let axiosRequest = axios.create({
    baseURL: `${api.baseUrl}/v${version}/${resource}`,
  });

  if (secured) {
    axiosRequest = axios.create({
      baseURL: `${api.baseUrl}/v${version}/${resource}`,
      headers: {
        Authorization: `${token_type.value} ${access_token.value}`,
        "content-type": "application/json",
      },
    });
  }

  axiosRequest.interceptors.response.use(responseInterceptor, onRejected);

  return axiosRequest;
}
