/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultObject } from "@/stores/player/types";

class ArrayUtils {
  public static UniqueBy(arr: Array<any>, key: string): Array<any> {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  public static Uniq = (array: string[]): string[] => {
    return array.filter((el, index, arr) => index == arr.indexOf(el));
  };

  public static setStringArrayToObjectArray = (array: string[] | null | undefined): Array<DefaultObject> => {
    const objectArray: DefaultObject[] = [];

    if (Array.isArray(array)) {
      array.forEach((contract: string, index: number) => {
        objectArray.push({ name: contract, value: index });
      });
    } else {
      console.warn("Invalid input: 'array' is null or undefined.");
    }

    return objectArray;
  };
}

export default ArrayUtils;
