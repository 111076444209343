import { AxiosResponse } from "axios";
import { createAxiosRequest } from "../api-base";
import { ChangePasswordBody, LoginBody, RecoverPasswordEmailBody, RecoverPasswordBody } from "./types";
import qs from "qs";

const resourceLogin = "login";
const resourceChangePassword = "reset-password";
const resourceRecoverPassword = "recover-password-update";
const resourceRecoverPasswordEmail = "recover-password-email";

export async function login(loginBody: LoginBody): Promise<AxiosResponse> {
  // qs.stringify the json you want to send if your data using application/x-www-form-urlencoded
  return await createAxiosRequest(resourceLogin, 1, false).post("/access-token", qs.stringify(loginBody));
}

export async function changePassword(changePasswordBody: ChangePasswordBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resourceChangePassword, 1, true).post("/", null, {
    params: { ...changePasswordBody },
  });
}

export async function recoverPassword(recoverPasswordEmailBody: RecoverPasswordEmailBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resourceRecoverPasswordEmail, 1).post("/", null, {
    params: { ...recoverPasswordEmailBody },
  });
}

export async function newPassword(recoverPasswordBody: RecoverPasswordBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resourceRecoverPassword, 1).post("/", null, {
    params: { ...recoverPasswordBody },
  });
}
