import { defineStore } from "pinia";
import { SearchFilter, SearchState } from "@/stores/search/types";
import { ClubsBody, PlayersBody } from "@/api/search/types";
import { createActionHelpers } from "@/stores/helpers/global-loader";
import { getClubs, getPlayers } from "@/api/search/search-api";
import SearchType from "@/common/constants/search";
import { ClubForPlayersBody } from "@/api/club/types";
import { getPlayersInClub } from "@/api/club/club-api";

const { startLoading } = createActionHelpers();

export const useSearchStore = defineStore("search", {
  state: (): SearchState => ({
    clubSearchResults: [],
    playerSearchResults: null,
    searchFilter: null,
    searchFor: SearchType.PLAYERS,
    searchPagination: {
      page_num: 1,
      page_size: 10,
    },
    showSearchResults: false,
  }),
  actions: {
    async getClubs(clubsBody: ClubsBody) {
      const response = await startLoading("search", getClubs(clubsBody));
      this.clubSearchResults = response.data?.clubs;
    },
    async getPlayers(playersBody: PlayersBody, loadSearchResults = false) {
      const response = await startLoading(loadSearchResults ? "searchWithResults" : "search", getPlayers(playersBody));
      this.playerSearchResults = response.data;
    },
    async getPlayersForClub(clubForPlayersBody: ClubForPlayersBody) {
      const response = await startLoading("searchWithResults", getPlayersInClub(clubForPlayersBody));
      this.playerSearchResults = { players: response.data };
    },
    async updateSearchFor(searchValue: string) {
      this.searchFor = searchValue;
    },
    async updateShowSearchResults(showSearchResults: boolean) {
      this.showSearchResults = showSearchResults;
    },
    async setSearchFilter(searchFilter: SearchFilter) {
      this.searchFilter = { ...searchFilter };
    }
  },
});
