<template>
  <Disclosure v-slot="{ open }" as="nav" class="bg-background-primary">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-20">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <router-link to="/search" @click="onNavigationClick('/search')">
              <img src="@/assets/images/gool_logo_light.svg" alt="Logo" />
            </router-link>
          </div>
          <div class="hidden md:block">
            <div class="ml-10 flex items-baseline space-x-4">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  selectedRoute === item.href ? 'font-bold border-b-primary-light-100 border-b-2' : '',
                  'py-1 text-sm text-white font-regular',
                ]"
                @click="onNavigationClick(item.href)"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <div class="mr-5">
              <app-select v-model="selectedCurrency" :is-loading="loadingUser" :options="currencyList" :title="$t(`common.currency`)"></app-select>
            </div>
            <div class="mr-5">
              <app-select v-model="selectedLanguage" :is-loading="loadingUser" :options="languageList"></app-select>
            </div>

            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <div>
                <MenuButton
                  class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span class="sr-only">Open user menu</span>
                  <div v-if="loadingUser" class="rounded-full bg-primary-light-100 animate-pulse h-10 w-10"></div>
                  <div v-else class="h-10 w-10 rounded-full bg-primary-light-100">
                    <p class="font-semibold text-white text-base pt-2">{{ avatarText }}</p>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-primary-light-100 z-10 focus:outline-none"
                >
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a
                      :href="item.href"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                      @click="onUserNavigationClick(item.name)"
                    >
                      {{ item.name }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="px-5 pt-2 pb-3 space-y-1 flex flex-col sm:px-3">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          :class="[item.current ? 'font-bold border-b-primary-light-100 border-b-2' : '', 'py-2 text-base text-white font-regular w-max']"
          :aria-current="item.current ? 'page' : undefined"
          @click="onNavigationClick(item.href)"
        >
          {{ item.name }}
        </DisclosureButton>
      </div>
      <div class="pt-4 pb-3 border-t border-white">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <div class="h-10 w-10 rounded-full bg-primary-light-100">
              <p class="font-semibold text-white text-center text-base pt-2">{{ avatarText }}</p>
            </div>
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <DisclosureButton
            v-for="item in userNavigation"
            :key="item.name"
            as="a"
            :href="item.href"
            class="block px-3 py-2 rounded-md text-base font-medium text-white"
            @click="onUserNavigationClick(item.name)"
          >
            {{ item.name }}
          </DisclosureButton>
        </div>
      </div>
      <div class="pt-4 pb-3 px-5 border-t border-white flex justify-between">
        <app-select v-model="selectedCurrency" :is-loading="loadingUser" :options="currencyList" :title="$t(`common.currency`)"></app-select>
        <app-select v-model="selectedLanguage" :is-loading="loadingUser" :options="languageList" :title="$t(`common.language`)"></app-select>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useAuthStore } from "@/stores/auth";
import { useLoaderStore } from "@/stores/loader";
import { useProfileStore } from "@/stores/profile";
import { storeToRefs } from "pinia";
import AppSelect from "@/components/ui/AppSelect.vue";
import { useI18n } from "vue-i18n";

import { updatePageData } from "@/common/utils/translations/set-initial-values";

const { locale, t } = useI18n({ useScope: "global" });
const authStore = useAuthStore();
const loaderStore = useLoaderStore();
const profileStore = useProfileStore();
const loadingUser = computed(() => loaderStore.getLoaderByName("user"));
const { currencyList, languageList, selectedCurrency, selectedLanguage, user } = storeToRefs(profileStore);
const selectedRoute = ref("/search");

const navigation = computed(() => [
  { name: t("navigation.home"), href: "/search" },
  { name: t("common.comparePlayers", 2), href: "/compare-players" },
]);

const userNavigation = computed(() => [
  { name: t("navigationUser.viewProfile"), href: "/view-profile" },
  { name: t("common.logout"), href: "/login" },
]);

const avatarText = computed(() => {
  const firstNameLetter = user.value?.first_name.charAt(0);
  const lastNameLetter = user.value?.last_name.charAt(0);
  return `${firstNameLetter?.toUpperCase()}${lastNameLetter?.toUpperCase()}`;
});

function setCurrentNavigationItem(routeHref: string) {
  selectedRoute.value = routeHref;
}

function onNavigationClick(selectedNavigationHref: string) {
  setCurrentNavigationItem(selectedNavigationHref);
}

function onUserNavigationClick(routeName: string) {
  if (routeName === t("common.logout")) {
    authStore.logout();
  }
}

onMounted(async () => {
  setCurrentNavigationItem(window.location.pathname);
  if (currencyList.value?.length === 0) profileStore.getCurrencyList();
  if (languageList.value?.length === 0) profileStore.getLanguageList();
  await profileStore.getUser();
  locale.value = user.value?.language;
});

watch(selectedCurrency, async (selectedCurrency, prevSelectedCurrency) => {
  if (prevSelectedCurrency !== null && selectedCurrency?.value !== user.value?.currency) {
    await profileStore.setUserCurrency({ currency: selectedCurrency?.value || prevSelectedCurrency.value });
    updatePageData();
  }
});

watch(selectedLanguage, async (selectedLanguage, prevSelectedLanguage) => {
  if (prevSelectedLanguage !== null && selectedLanguage?.value !== user.value?.language) {
    await profileStore.setUserLanguage({ language: selectedLanguage?.value || prevSelectedLanguage.value });
    locale.value = selectedLanguage?.value;
    updatePageData();
  }
});
</script>
