import en from "@/translations/en";
import de from "@/translations/de";
import { createI18n } from "vue-i18n";

type MessageSchema = typeof en;

const messages = {} as { [key: string]: MessageSchema };
const supported = ["en", "de"];
const availableLocales = [
  {
    code: "en",
    flag: "us",
    label: "English",
    messages: en,
  },
  {
    code: "de",
    flag: "ger",
    label: "German",
    messages: de,
  },
];

let locale = "en";

try {
  const { 0: browserLang } = navigator.language.split("-");

  if (supported.includes(browserLang)) locale = browserLang;
} catch (e) {
  console.log(e);
}

availableLocales.forEach((l) => {
  messages[l.code] = l.messages;
});

export const i18n = createI18n({
  legacy: false,
  locale,
  fallbackLocale: import.meta.env.VITE_APP_DEFAULT_LANGUAGE as string,
  messages,
  mode: "composition",
});

export default i18n;
