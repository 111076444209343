import { CheckboxObject, PlayerCompareItem, PlayerImpactAdvance } from "@/stores/player/types";

export const mapPlayerCompare = (dataToMap: PlayerCompareItem[], dataToAdd: any, key: string, compareNumber: number) => {
  return dataToMap.map((item: PlayerCompareItem, index: number) => (index === compareNumber ? { ...item, [key]: dataToAdd } : item));
};

export const sortBySelectedAttributes = (selectedAttributes: CheckboxObject[], dataToSort: PlayerImpactAdvance) => {
  const selectedObjects = [] as any;
  selectedAttributes.forEach((attribute: CheckboxObject) => {
    if (dataToSort !== null) {
      const foundImpactObject = Object.entries(dataToSort).find(([key, value]) => value.surface_name === attribute.name);
      selectedObjects.push(foundImpactObject);
    }
  });

  return selectedObjects;
};

export const sortValuesAlphabetically = (dataToSort: PlayerImpactAdvance, isAscending: boolean) => {
  return Object.fromEntries(
    isAscending
      ? Object.entries(dataToSort).sort((a, b) => a[1].surface_name.localeCompare(b[1].surface_name))
      : Object.entries(dataToSort)
          .sort((a, b) => a[1].surface_name.localeCompare(b[1].surface_name))
          .reverse()
  );
};

export const sortValuesHighToLow = (dataToSort: PlayerImpactAdvance, isHigh: boolean, isContributionOnMarketValue: boolean) => {
  return Object.fromEntries(
    isHigh
      ? Object.entries(dataToSort)
          .sort((a, b) => {
            return isContributionOnMarketValue
              ? a[1].raw_value - b[1].raw_value
              : a[1].average_constribution.raw_value! - b[1].average_constribution.raw_value!;
          })
          .reverse()
      : Object.entries(dataToSort).sort((a, b) => {
          return isContributionOnMarketValue
            ? a[1].raw_value - b[1].raw_value
            : a[1].average_constribution.raw_value! - b[1].average_constribution.raw_value!;
        })
  );
};

export const setHighestRelativeValue = (data: PlayerCompareItem[]) => {
  if (hasPlayerData(data) && !onlyOnePlayerHasData(data)) {
    const playerIndex: number = firstPlayerWithDataIndex(data) as number;

    Object.entries(data[playerIndex].impactAdvanced as PlayerImpactAdvance).forEach(([key, value]) => {
      const player1Value = data[0].impactAdvanced !== null ? data[0].impactAdvanced[key as keyof PlayerImpactAdvance]?.percent : 0;
      const player2Value = data[1].impactAdvanced !== null ? data[1].impactAdvanced[key as keyof PlayerImpactAdvance]?.percent : 0;
      const player3Value = data[2].impactAdvanced !== null ? data[2].impactAdvanced[key as keyof PlayerImpactAdvance]?.percent : 0;

      const valuesArray = [player1Value, player2Value, player3Value]
      const hasOnlyNegativeValues = valuesArray.every((value: number) => value <= 0);
      const indexOfMaxValue = hasOnlyNegativeValues ? closestToZeroIndex(valuesArray) : valuesArray.reduce((iMax, x, i, arr) => x >= arr[iMax] ? i : iMax, 0);

      if (data[0].impactAdvanced !== null) {
        data[0].impactAdvanced[key as keyof PlayerImpactAdvance].highest_value = indexOfMaxValue === 0;
      }

      if (data[1].impactAdvanced !== null) {
        data[1].impactAdvanced[key as keyof PlayerImpactAdvance].highest_value = indexOfMaxValue === 1;
      }

      if (data[2].impactAdvanced !== null) {
        data[2].impactAdvanced[key as keyof PlayerImpactAdvance].highest_value = indexOfMaxValue === 2;
      }
    })
  }
}

export const hasPlayerData = (data: PlayerCompareItem[]) => {
  return data.some((item: PlayerCompareItem) => item?.general !== null)
}

const onlyOnePlayerHasData = (data: PlayerCompareItem[]) => {
  let playerDataCount = 0;

  data.forEach((item: PlayerCompareItem) => {
    if (item.general !== null) {
      playerDataCount++;
    }
  })

  return playerDataCount === 1;
}

const firstPlayerWithDataIndex = (data: PlayerCompareItem[]) => {
  const player1 = data[0];
  const player2 = data[1];
  const player3 = data[2];

  if (player1?.general !== null) {
    return 0;
  } else if (player2?.general !== null) {
    return 1;
  } else if (player3?.general !== null) {
    return 2;
  }
}

const closestToZeroIndex = (numbers: number[]) => {
  let closest = 0;

  for (let i = 0; i < numbers.length ; i++) {
    if (closest === 0) {
      closest = numbers[i];
    } else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
      closest = numbers[i];
    } else if (numbers[i] < 0 && - numbers[i] < Math.abs(closest)) {
      closest = numbers[i];
    }
  }

  return numbers.indexOf(closest);
}
