import { useToast } from "vue-toastification";

const toast = useToast();

export function showSuccess(message: string): void {
  toast.success(message, { timeout: 3000 });
}

export function showError(message: string): void {
  toast.error(message, { timeout: 3000 });
}

export function showWarning(message: string): void {
  toast.warning(message, {
    timeout: false,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.38,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false });
}
