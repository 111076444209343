<template>
  <div class="w-full flex items-center justify-center">
    <svg class="spinner" viewBox="0 0 50 50" :style="setDimensions">
      <circle :class="[isPrimary ? 'path-primary' : 'path-secondary', 'path']" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "AppSpinner",
  props: {
    isPrimary: {
      type: Boolean,
      required: false,
      default: true,
    },
    height: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const setDimensions = computed(() => `height: ${props.height}px; width: ${props.width}px;`);

    return { setDimensions };
  },
});
</script>

<style scoped>
.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
}

.spinner .path {
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

.spinner .path-primary {
  stroke: #9b37ff;
}

.spinner .path-secondary {
  stroke: #ffffff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
