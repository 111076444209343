import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthRoutes from "@/router/auth.routes";
import ErrorType from "@/common/constants/error";
import { useAuthStore } from "@/stores/auth";

// Routes to sections of the app should be kept in separate files
// eg: auth.routes.ts

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "root",
    redirect: "/login",
  },
  {
    path: "/compare-players",
    name: "compare-players",
    component: () => import("@/views/ComparePlayers.vue"),
    props: true,
    meta: {
      title: "Gool | Compare Players" as string,
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/SearchView.vue"),
    meta: {
      title: "Gool | Search" as string,
      layout: "default",
      requiresAuth: true,
    },
  },
  {
    path: "/view-profile",
    name: "view-profile",
    component: () => import("@/views/ViewProfile.vue"),
    meta: {
      title: "Gool | Player" as string,
      layout: "empty",
      requiresAuth: true,
    },
  },
  {
    path: "/view-player/:id",
    name: "view-player",
    component: () => import("@/views/ViewPlayer.vue"),
    props: true,
    meta: {
      title: "Gool | Player" as string,
      layout: "empty",
      requiresAuth: true,
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "@/views/error/Error.vue"),
    props: true,
    meta: {
      title: "Unknown Error" as string,
      layout: "default",
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "notfound" */ "@/views/error/Error.vue"),
    props: () => ({ errorType: ErrorType.NOT_FOUND }),
    meta: {
      title: "Page not found",
      layout: "default",
      requiresAuth: false,
    },
  },
  ...AuthRoutes,
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {

  const authStore = useAuthStore();
  if (to.meta && to.meta.title) {
    document.title = to.meta.title as string || "Gool";
  }

  if (to.meta.requiresAuth && authStore.access_token === "") {
    next({ name: "login" });
    return;
  }

  if (authStore.login_first_time && to.name !== "change-password") {
    next({ name: "change-password" });
    return;
  }

  if ((from.name == "login" || from.name == "change-password") && authStore.access_token !== "" && !authStore.login_first_time && to.name !== "search") {
    next({ name: "search" });
    return;
  }
  if (from.fullPath == "/" && authStore.access_token !== "" && !authStore.login_first_time && !to.meta.requiresAuth) {
    next({ name: "search" });
    return;
  }
  next();
  return;

});

export default router;
