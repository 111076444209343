import { AxiosResponse } from "axios";
import { createAxiosRequest } from "@/api/api-base";
import { CurrencyBody, LanguageBody, UserBody } from "@/api/profile/types";

const resource = "user";

export async function getUser(): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).get("/me");
}

export async function getUserSearchHistory(): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).get("/me/search_history");
}

export async function updateUser(userBody: UserBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).put("/me", userBody.body, {
    params: { ...userBody.queryParameters },
  });
}

export async function updateUserCurrency(currencyBody: CurrencyBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).put("/me/currency", null, {
    params: { ...currencyBody },
  });
}

export async function updateUserLanguage(languageBody: LanguageBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).put("/me/language", null, {
    params: { ...languageBody },
  });
}
