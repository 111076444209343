import { createAxiosRequest } from "@/api/api-base";
import { AxiosResponse } from "axios";
import { ClubsBody, PlayersBody } from "@/api/search/types";
import qs from 'qs';

const resource = "search";

export async function getClubs(clubsBody: ClubsBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).get("/club", {
    params: { ...clubsBody },
  });
}

export async function getPlayers(playersBody: PlayersBody): Promise<AxiosResponse> {
  return await createAxiosRequest(resource, 1, true).get("/players", {
    params: { ...playersBody },
    paramsSerializer: {
      serialize: (params: Record<string, any>): string => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    },
  });
}
