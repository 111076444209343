import router from "@/router";

import { useClubStore } from "@/stores/club";
import { usePlayerStore } from "@/stores/player";
import { useProfileStore } from "@/stores/profile";
import { useSearchStore } from "@/stores/search";
import { storeToRefs } from "pinia";

import { contractTillText } from "@/common/utils/string-utils";

import { DefaultObject } from "@/stores/player/types";
import { PlayerSearchResultItem } from "@/stores/search/types";

/*
 A function to update values when the language or currency values are changed
 */
export function updatePageData(): void {
  const clubStore = useClubStore();
  const playerStore = usePlayerStore();
  const profileStore = useProfileStore();
  const searchStore = useSearchStore();

  const { searchFilter } = storeToRefs(searchStore);
  const currentRoute = router.currentRoute.value.name;
  if (currentRoute === "search") {
    if (searchStore.clubSearchResults.length > 0) {
      searchStore.getPlayersForClub({ club_id: searchFilter.value?.club?.club_id || 0 });
    } else if ((searchStore.playerSearchResults?.players as PlayerSearchResultItem[])?.length > 0) {
      searchStore.getPlayers(
        {
          search_name: searchFilter.value?.searchText || "",
          age_min: searchFilter.value?.age[0],
          age_max: searchFilter.value?.age[1],
          market_value_min: searchFilter.value?.marketValue[0],
          market_value_max: searchFilter.value?.marketValue[1],
          weight_min: searchFilter.value?.weight[0],
          weight_max: searchFilter.value?.weight[1],
          height_min: searchFilter.value?.height[0],
          height_max: searchFilter.value?.height[1],
          foot: searchFilter.value?.foot?.name,
          position: searchFilter.value?.position?.map(i=>i.name),
          club: searchFilter.value?.club?.club_id,
          nationality: searchFilter.value?.nationality?.name,
          contract_duration: contractTillText(
            searchFilter.value?.contractTillMonth as DefaultObject,
            searchFilter.value?.contractTillYear as DefaultObject
          ),
        },
        true
      );
    }

    clubStore.getClubs();
    playerStore.getEstimatedMarketValueRange();
    playerStore.getPlayerFoot();
    playerStore.getPlayerNationalities();
    playerStore.getPlayerPositions();
    profileStore.getUserSearchHistory();
  } else if (currentRoute === "compare-players") {
    playerStore.setPlayerCompareInitialValues();
  }
}
