import { Currency } from "@/stores/search/types";
import { CurrentMarketValue, DefaultObject, PlayerBreakdownItem, PlayerImpactAdvanceItem } from "@/stores/player/types";
import { Language, Locales } from "@/common/constants/profile";


export const strongPasswordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

export const thousandSeparator = (value: number): string => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export const contractTillText = (contractTillMonth: DefaultObject, contractTillYear: DefaultObject): string => {
  if (Object.keys(contractTillMonth as DefaultObject).length === 0 && Object.keys(contractTillYear as DefaultObject).length === 0) {
    return "";
  } else if (Object.keys(contractTillMonth as DefaultObject).length === 0 && Object.keys(contractTillYear as DefaultObject).length > 0) {
    return contractTillYear?.name;
  } else if (Object.keys(contractTillMonth as DefaultObject).length > 0 && Object.keys(contractTillYear as DefaultObject).length === 0) {
    return contractTillMonth?.name;
  } else {
    return `${contractTillMonth?.name}.${contractTillYear?.name}`;
  }
};

export const currencyObjectToString = (
  currencyObject: Currency | CurrentMarketValue | PlayerBreakdownItem | PlayerImpactAdvanceItem,
  selectedLanguage: string,
  showCurrencySymbol = true
): string => {
  if (currencyObject?.value != null) {
    const valueLocale = selectedLanguage === Language.GERMAN ? currencyObject?.value.toString().replace(".", ",").replace("-", "") : currencyObject?.value.toString().replace("-", "");
    return showCurrencySymbol ? `${currencyObject?.currency} ${valueLocale}${currencyObject?.unit}` : `${valueLocale}${currencyObject?.unit}`;
  }
  return "---";
};

export const currencyRawObjectToString = (currencyObject: PlayerImpactAdvanceItem | Currency, selectedLanguage: string): string => {
  if (currencyObject?.raw_value != null) {
    const locale = selectedLanguage === Language.GERMAN ? Locales.DE : Locales.EN;
    return `${currencyObject?.currency} ${currencyObject?.raw_value.toLocaleString(locale)}`;
  }
  return "---";
};

export const stringToLocaleCurrency = (value: string, selectedLanguage: string): string => {
  const locale = selectedLanguage === Language.GERMAN ? Locales.DE : Locales.EN;
  return parseInt(value).toLocaleString(locale);
};
