<template>
  <div class="h-screen">
    <div class="content m-auto w-full h-screen grid sm:grid-cols-1 md:grid-cols-2">
      <div class="hidden md:block h-screen p-0 left-side-background" />
      <div class="flex justify-center align-middle">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
}
</script>

<style scoped>
.left-side-background {
  background-image: url("@/assets/images/auth/auth.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}
</style>
